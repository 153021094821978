import keycodes from 'Utilities/keycodes'

// Example:
//  <textarea ct-textarea=true ct-textarea-min-rows=5></textarea>
class Textarea {
  constructor({ element: input }) {
    this.input = input

    this.minRows = parseInt(input.getAttribute('ct-textarea-min-rows')) || 0

    // Clear the textarea and calculate the base height
    this.savedValue = input.value
    this.value = ''
    this.baseScrollHeight = input.scrollHeight
    this.value = this.savedValue

    this.calculateRows = this.calculateRows.bind(this)

    this.attachListeners()
  }

  attachListeners() {
    this.input.addEventListener('keydown', this.calculateRows)
  }

  calculateRows(evt) {
    this.rows = 0

    // "Enter" doesn't cause the scrollHeight to adjust so we need to offset it
    const offset = keycodes[evt.which] === 'ENTER' ? 1 : 0
    const newRows = Math.ceil((this.input.scrollHeight - this.baseScrollHeight) / 18)

    this.rows = newRows > this.minRows ? newRows + offset : newRows
  }

  set rows(rows) {
    this.input.rows = this.minRows + rows
    return
  }
}

export default Textarea
