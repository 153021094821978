import Awesomplete from 'awesomplete'

class AutoComplete {
  constructor({ element }) {
    // this input is used for user entry
    this.element = element
    // Use this option if the autocomplete list contains Object not flat values
    this.labeled = element.getAttribute('ct-autocomplete-labeled')

    if (this.labeled) {
      // dup and hide the input to be submitted
      this.valueElement = element.cloneNode(true)
      this.valueElement.type = 'hidden'
      this.element.closest('form').appendChild(this.valueElement) // Append to form
    }

    this.url = element.getAttribute('ct-autocomplete-path') || `/value_sets/${this.valueSetName}`
    this.cache = element.getAttribute('ct-autocomplete-cache') === 'true'
    this.request = new XMLHttpRequest()

    this.onLoad = this.onLoad.bind(this)
    this.autoComplete = this.autoComplete.bind(this)

    this.request.addEventListener('load', this.onLoad)
    this.autoComplete()
  }

  get valueSetName() {
    return this.element.getAttribute('value_set')
  }

  get valueSet() {
    return JSON.parse(localStorage.getItem(this.valueSetName))
  }

  set valueSet(list) {
    localStorage.setItem(this.valueSetName, JSON.stringify(list))
  }

  onLoad({ target }) {
    if (target.status === 200) {
      this.valueSet = JSON.parse(target.responseText).valueSet
      this.cache = true
      this.autoComplete()
    }
  }

  autoComplete() {
    // If there is no valueSet or cache is set to false then make the AJAX call
    // this.onLoad will invoke this.addAutoComplete after it has
    // parsed the valueSet from the response;
    if (!this.valueSet || !this.cache) {
      this.request.open('GET', this.url, true)
      this.request.send()
      return
    }
    const options = { list: this.valueSet, autoFirst: true }

    if (this.labeled) {
      options.replace = (text) => {
        this.element.value = text
        this.valueElement.value = text.value
      }
    }
    new Awesomplete(this.element, options) // eslint-disable-line no-new
  }
}

export default AutoComplete
