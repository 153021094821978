import keycodes from 'Utilities/keycodes'

import AutoComplete from './autocomplete'
import DiagnosisTemplate from './diagnosis_template'

class MultiAutoComplete {
  constructor({ element, utils }) {
    this.utils = utils
    this.container = element
    this.autoComplete = new AutoComplete({ utils, element: utils.cleanAttributes(this.inputs[0]) })
    this.type = this.container.getAttribute('ct-multi-autocomplete-type') || 'diagnosis'
    this.parent = this.container.getAttribute('ct-multi-autocomplete-parent') || 'care_plan'

    this.attachListeners = this.attachListeners.bind(this)
    this.addAutoComplete = this.addAutoComplete.bind(this)
    this.createTemplate = this.createTemplate.bind(this)
    this.attachListeners()
  }

  get addButton() {
    return this.utils.queryCTSelector('add-autocomplete', this.container)
  }

  get removeButtons() {
    return this.utils.queryCTSelectorAll('remove-autocomplete', this.container)
  }

  get inputs() {
    return this.container.querySelectorAll('input')
  }

  get inputCount() {
    return this.container.querySelectorAll('input[readonly]').length
  }

  createTemplate(value) {
    const template = new DiagnosisTemplate(value, this.inputCount, this.parent)
    return template.templateString
  }

  attachListeners() {
    this.addButton.addEventListener('click', this.addAutoComplete)
    this.inputs[0].addEventListener('keydown', (evt) => {
      if (keycodes[evt.which] === 'ENTER') {
        evt.preventDefault()
        this.addAutoComplete(evt)
      }
      return
    })
    this.removeButtons.forEach((button) => {
      button.addEventListener('click', this.removeAutoComplete)
    })
  }

  removeAutoComplete(evt) {
    evt.preventDefault()
    const wrapper = this.parentElement

    const name = wrapper.querySelector("input[type='text']").getAttribute('name')
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', name.replace(/^(.*\[).*?(\])$/, '$1_destroy$2'))
    hiddenInput.setAttribute('value', '1')
    hiddenInput.setAttribute('readonly', null)
    wrapper.parentElement.appendChild(hiddenInput)
    wrapper.style.visibility = 'hidden'
    wrapper.style.height = 0
    wrapper.style.margin = 0
  }

  addAutoComplete(evt) {
    evt.preventDefault()
    const valueElement = this.autoComplete.valueElement || this.inputs[0]
    const value = valueElement.value
    if (!value) return

    const template = this.createTemplate(value)
    this.container.insertAdjacentHTML('beforeend', template)
    valueElement.value = ''
    this.inputs[0].value = ''
    this.attachListeners()
  }
}

export default MultiAutoComplete
