class Toggle {
  constructor({ element }) {
    this.trigger = element.querySelector(element.getAttribute('ct-toggle-trigger')) || element
    this.target = document.querySelector(element.getAttribute('ct-toggle')) || element
    this.toggleClass = element.getAttribute('ct-toggle-class')

    this.attachListeners()
  }

  attachListeners() {
    this.trigger.addEventListener('click', this.toggle.bind(this))
    this.trigger.addEventListener('keydown', this.keyDown.bind(this))
  }

  toggle(evt) {
    evt.preventDefault()
    window.requestAnimationFrame(() => this.target.classList.toggle(this.toggleClass))
  }

  keyDown(evt) {
    const { key } = evt;
    if (key === 'Enter' || key === ' ') {
      this.toggle(evt);
    }
  }
}

// Ultra custom for Care Plan creator Order Section
class ToggleSelect extends Toggle {
  constructor({ element, utils }) {
    super({ element, utils })
    this.section = document.querySelector(element.getAttribute('ct-toggle-select-target') || '#order-form')
    const value = this.trigger.checked || this.trigger.value
    this.form = this.trigger.closest('form')
    this.inputs = this.form.querySelectorAll('[ct-form-section]')
    this.inputs.forEach(this.toggleInput.bind(null, value))
  }

  attachListeners() {
    this.trigger.addEventListener('change', this.toggle.bind(this))
  }

  toggle(evt) {
    // Close the form and reset it, if the select box is being set to them empty value
    if (!evt.target.value) {
      this.section.classList.add('form--hidden')
      this.form.reset()
      return
    }

    const value = evt.target.checked || evt.target.value
    this.inputs.forEach(this.toggleInput.bind(null, value))
    this.section.classList.remove('form--hidden')
  }

  toggleInput(value, input) {
    const section = input.getAttribute('ct-form-section')
    const inputWrapper = input.closest('div.input')
    if (section && section.includes(value)) {
      inputWrapper.classList.remove('input--hidden')
      inputWrapper.querySelectorAll(`input[name='${input.name}']`).forEach((input) => {
        input.removeAttribute('disabled')
      })
    } else {
      inputWrapper.classList.add('input--hidden')
      inputWrapper.querySelectorAll(`input[name='${input.name}']`).forEach((input) => {
        input.setAttribute('disabled', 'disabled')
      })
    }
  }
}

class ToggleInput extends Toggle {
  constructor({ element, utils }) {
    super({ element, utils })
    this.targets = document.querySelectorAll(element.getAttribute('ct-toggle-input')) || element
  }

  attachListeners() {
    this.trigger.addEventListener('focus', this.reveal.bind(this))
  }

  reveal(evt) {
    evt.preventDefault()
    this.targets.forEach((target) => {
      window.requestAnimationFrame(() => target.classList.remove(this.toggleClass))
    })
  }
}

export { Toggle, ToggleSelect, ToggleInput }
