import { t } from 'i18n'
import symbolDefs from 'symbol-defs.svg'

class DiagnosisTemplate {
  constructor(value, inputCount, parent) {
    // this is a hack; previous code was vulnerable to self-XSS so this escaper
    // is to patch it temporarily until we replace this component
    function textEscaper() {
      let charMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '`': '&#x60;'
      };

      let escaper = function(match) {
        return charMap[match];
      }

      let source = '(?:' + Object.keys(charMap).join('|') + ')';
      let testRegexp = RegExp(source);
      let replaceRegexp = RegExp(source, 'g');
      return function(string) {
        string = string == null ? '' : '' + string;
        return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
      };
    }

    function escape(str) {
      return textEscaper()(str)
    }

    this.templateString = `
      <div class='input multi-autocomplete required ${parent}_diagnoses_condition_code careplan-details__diagnosis'>
        <input readonly
               tabindex='-1'
               type='text'
               value='${escape(value)}'
               name='${parent}[diagnoses_attributes][${inputCount}][condition_code]'
               id='${parent}_diagnoses_attributes_${inputCount}_condition_code'>
        <button class="btn btn--icon btn--secondary careplan-details__existing-diagnosis__remove-btn" ct-remove-autocomplete="true">
          <div class="icon btn__icon careplan-details__existing-diagnosis__remove-icon">
            <svg class="icon icon_trash">
              <use xlink:href="${symbolDefs}#icon_trash"></use>
            </svg>
          </div>
          ${this.deleteText}
        </button>
      </div>
    `
  }

  get deleteText() {
    return t('remove', { scope: 'ct.components.diagnosis_template' })
  }
}

export default DiagnosisTemplate
