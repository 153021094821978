import symbolDefs from 'symbol-defs.svg'
import moment from 'ct-moment'
import { t } from 'i18n'

class RemoteHandlers {
  constructor({ element }) {
    this.element = element

    this.attachListeners()
  }

  attachListeners() {
    const handler = this.element.getAttribute('ct-remote')

    if (successHandlers[handler]) {
      this.element.addEventListener('ajax:success', successHandlers[handler], false)
    }

    if (failureHandlers[handler]) {
      this.element.addEventListener('ajax:error', failureHandlers[handler], true)
    }
  }
}

const successHandlers = {
  deleteDocument: (evt) => {
    evt.target.closest('tr').remove()
    return
  },

  documentCreated: (evt) => {
    const { medicalDocument } = evt.detail[0]
    const tableBody = document.querySelector('#document-table')
    const row = document.createElement('tr')

    row.innerHTML = medicalDocumentTemplate(medicalDocument)

    tableBody.appendChild(row)
    new RemoteHandlers({ element: row.querySelector('a[ct-remote]') }) // eslint-disable-line no-new
    evt.target.reset()
    return
  },
}

const failureHandlers = {
  patientForm: (evt) => {
    const errors = evt.detail[0]
    const prefix = 'care_plan_'

    Object.keys(errors).forEach((error) => {
      const selector = `#${prefix}${error.replace(/\./g, '_attributes_')}`
      const input = document.querySelector(selector)
      input.setCustomValidity(errors[error][0])
      input.checkValidity()
    })
    return
  },
}

function medicalDocumentTemplate(medicalDocument) {
  const i18nOpts = { scope: 'ct.components.remote_handlers.medical_document' }

  const createdAt = moment(medicalDocument.createdAt).format('MMM DD, YYYY')
  return `
    <td data-th="${t('event_at', i18nOpts)}">${medicalDocument.eventAt || ''}</td>
    <td data-th="${t('name', i18nOpts)}">${medicalDocument.name}</td>
    <td data-th="${t('document_file_name', i18nOpts)}">${medicalDocument.documentFileName}</td>
    <td data-th="${t('author', i18nOpts)}">${medicalDocument.author.name}</td>
    <td data-th="${t('created_at', i18nOpts)}">${createdAt}</td>
    <td data-th="${t('tags', i18nOpts)}">${medicalDocument.tags}</td>
    <td>
      <div class="table__flex-cell">
        <a href="${medicalDocument.url}" target="_blank">${t('view', i18nOpts)}</a>
        <a data-remote=true data-method="delete" ct-remote="deleteDocument" href="${medicalDocument.url}">
          <div class="icon icon-container icon_close icon--large">
            <svg class="icon icon_close">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${symbolDefs}#icon_close"></use>
            </svg>
          </div>
        </a>
      </div>
    </td>`
}

export default RemoteHandlers
