class RemoveFields {
  // TODO: Support _destroy when used for data that needs to be persisted

  constructor({ element }) {
    this.element = element

    element.addEventListener('click', this.onClick.bind(this))
  }

  onClick(event) {
    event.preventDefault()

    if (this.canRemove()) {
      this.remove()
    }
  }

  get form() {
    return this.element.closest('form')
  }

  get inputName() {
    return this.element.getAttribute('ct-remove-fields')
  }

  get fields() {
    return this.form.getElementsByClassName(this.inputName)
  }

  canRemove() {
    return this.fields.length > 1
  }

  remove() {
    const wrapper = this.element.closest(`.${this.inputName}`)
    wrapper.remove()
  }
}

export default RemoveFields
