import Loader from './loader';

import Accordion from './components/accordion';
import AutoComplete from './components/autocomplete';
import AddFields from './components/add_fields';
import BrowserCheck from './components/browser_check';
import RemoveFields from './components/remove_fields';
import FormAutosave from './components/form_autosave';
import Dropdown from './components/dropdown';
import FileUpload from './components/file_upload';
import FormOnlySelected from './components/form_only_selected';
import FormSelectAll from './components/form_select_all';
import Modal from './components/modal';
import ModalClose from './components/modal_close';
import MultiAutoComplete from './components/multi_autocomplete';
import Validator from './components/validator';
import ReactDateInput from './components/react_date_input';
import ReactDatetimeInput from './components/react_datetime_input';
import ReactDobInput from './components/react_dob_input';
import RemoteHandlers from './components/remote_handlers';
import Truncate from './components/truncate';
import Textarea from './components/textarea';

import { LinkConfirm, FormConfirm, RemoteFormConfirm, DeleteConfirm } from './components/confirm';

import { Toggle, ToggleSelect, ToggleInput } from './components/toggle';

const loader = new Loader({
  'link-confirm': LinkConfirm,
  'form-confirm': FormConfirm,
  'remote-form-confirm': RemoteFormConfirm,
  'delete-confirm': DeleteConfirm,
  'form-autosave': FormAutosave,
  validator: Validator,
  autocomplete: AutoComplete,
  'multi-autocomplete': MultiAutoComplete,
  dropdown: Dropdown,
  'form-select-all': FormSelectAll,
  'form-only-selected': FormOnlySelected,
  'file-upload': FileUpload,
  toggle: Toggle,
  'toggle-input': ToggleInput,
  'toggle-select': ToggleSelect,
  accordion: Accordion,
  modal: Modal,
  'modal-close': ModalClose,
  'close-modal': ModalClose,
  'react-date-input': ReactDateInput,
  'react-datetime-input': ReactDatetimeInput,
  'react-dob-input': ReactDobInput,
  'add-fields': AddFields,
  'remove-fields': RemoveFields,
  remote: RemoteHandlers,
  textarea: Textarea,
  truncate: Truncate,
  'browser-check': BrowserCheck,
});

export default loader;
