class FormAutosave {
  constructor({ element, utils }) {
    this.utils = utils
    this.targetForm = element
    this.changed = false

    this.saveForm = this.saveForm.bind(this)

    this.intervalLength = parseInt(this.targetForm.getAttribute('ct-form-autosave-interval') || '120000', 10)

    const isRemote = this.targetForm.getAttribute('ct-remote-form')
    if (isRemote) {
      this.attachListeners()
      setInterval(this.saveForm, this.intervalLength) // time in ms
    }
  }

  get query() {
    return ['input.string', 'input.date', 'input.boolean', 'textarea.text', 'select.date', 'select.select']
      .map((selector) => `${selector}:not(:disabled):not([readonly])`)
      .join(', ')
  }

  attachListeners() {
    this.targetForm.querySelectorAll(this.query).forEach((el) => {
      el.addEventListener('change', () => {
        this.changed = true
      })
    })

    this.targetForm.addEventListener('submit', () => {
      this.changed = false
    })
  }

  saveForm() {
    const style = window.getComputedStyle(this.targetForm)
    if (style.visibility === 'visible' && this.changed) {
      this.targetForm.dispatchEvent(new CustomEvent('autosubmit'))
      this.changed = false
    }
  }
}

export default FormAutosave
