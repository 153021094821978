import vex from 'CT/vex'

/*
  <button id="open">Open it</button> <- Trigger

  Content inside of the ct-modal element will be displayed inside the modal
  <div ct-modal=true ct-modal-trigger='#open'>
    <div>Hello world</div>
    <b>BOLD TEXT TOO!</b>
  </div>
*/
class Modal {
  constructor({ element }) {
    this.content = element.innerHTML
    this.triggers = document.querySelectorAll(element.getAttribute('ct-modal-trigger'))

    this.attachListeners()
  }

  attachListeners() {
    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', (evt) => {
        evt.preventDefault()
        const modal = vex.open({ unsafeContent: this.content })

        document.body.loader(modal.contentEl)
      })
    })
  }
}

export default Modal
