import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import DOBPicker from 'Components/Forms/DOBPicker'

class ReactDobInput {
  constructor({ element }) {
    this.container = element
    const input = element.querySelector('input')

    this.createInterface(input)
    input.remove()
  }

  createInterface({ id, name, value, disabled }) {
    const wrapper = document.createElement('div')

    const lastAudited = this.container.querySelector('.audited')
    const error = this.container.querySelector('.error')

    if (lastAudited) {
      this.container.insertBefore(wrapper, lastAudited)
    } else if (error) {
      this.container.insertBefore(wrapper, error)
    } else {
      this.container.appendChild(wrapper)
    }

    ReactDOM.render(<DOBWrapper id={id} name={name} value={value} disabled={disabled} />, wrapper)
  }
}

class DOBWrapper extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      value: props.value,
    }
  }

  handleChange({ target: { value } }) {
    this.setState({ value })
  }

  render() {
    return (
      <Fragment>
        <DOBPicker
          name={this.props.name}
          onChange={this.handleChange}
          value={this.state.value}
          disabled={this.props.disabled}
        />
        <input id={this.props.id} name={this.props.name} type="hidden" value={this.state.value} />
      </Fragment>
    )
  }
}

export default ReactDobInput
export { DOBWrapper }
