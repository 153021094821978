import React from 'react'
import ReactDOM from 'react-dom'
import { t } from 'i18n'

import { MAX_FILE_SIZE } from 'Utilities/constants'
import Icon from 'Components/Base/Icon'

class FileUpload {
  constructor({ element }) {
    this.container = element
    this.input = element.querySelector('input')
    this.input.style.visibility = 'hidden'
    this.input.style.width = 0
    this.input.style.float = 'right'
    this.maxFileSize = MAX_FILE_SIZE

    this.createButton = this.createButton.bind(this)
    this.createFileList = this.createFileList.bind(this)
    this.onChange = this.onChange.bind(this)
    this.resetFileList = this.resetFileList.bind(this)
    this.attachListeners = this.attachListeners.bind(this)

    this.createButton()
    this.fileList = this.createFileList()
    this.attachListeners()
  }

  createButton() {
    const label = this.container.querySelector('label')
    let text
    if (label) {
      text = label.innerText
      label.remove()
    } else {
      text = t('attach_document', { scope: 'ct.components.file_upload' })
    }
    const buttonWrapper = document.createElement('div')
    this.container.appendChild(buttonWrapper)

    const button = (
      <button className="btn" onClick={this.onClick.bind(this)}>
        {text}{' '}
        <div className="icon icon-container icon_upload">
          <Icon name="upload" />
        </div>
      </button>
    )
    ReactDOM.render(button, buttonWrapper)
  }

  createFileList() {
    const list = document.createElement('ul')
    this.container.appendChild(list)
    return list
  }

  attachListeners() {
    this.input.addEventListener('change', this.onChange)
    this.input.form.addEventListener('reset', this.resetFileList)
  }

  onClick(evt) {
    evt.preventDefault()
    this.input.click()
  }

  removeExtension(fileName) {
    return fileName.replace(/\.[^/.]+$/, '')
  }

  onChange() {
    const files = []
    const nameInput = document.getElementById('medical_document_name')
    for (let i = 0; i < this.input.files.length; i++) {
      const fileName = this.input.files[i].name

      if (this.input.files[i].size > this.maxFileSize) {
        this.input.setCustomValidity(t('oversized_file', { scope: 'ct.components.validator' }))
      }

      if (nameInput && nameInput.value === '') {
        nameInput.value = this.removeExtension(fileName)
      }
      files.push(`<li>${fileName}</li>`)
    }
    this.fileList.innerHTML = files.join('')
  }

  resetFileList() {
    this.fileList.innerHTML = ''
  }
}

export default FileUpload
