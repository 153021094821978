import vex from 'CT/vex'

// <a href="#" ct-modal-close>Close</a>
// <a href="#" ct-close-modal>Close</a>
class ModalClose {
  constructor({ element }) {
    element.addEventListener('click', this.cancel)
  }

  cancel(evt) {
    evt.preventDefault()
    vex.closeAll()
    return
  }
}

export default ModalClose
