import vex from '../vex';
import { t } from 'i18n';

class Confirm {
  constructor({ element, attribute, okButton }) {
    this.element = element;
    this.message = this.element.getAttribute(attribute);
    this.isOpen = false;
    this.okButton = okButton;
    this.attachListeners();
  }

  popup(evt) {
    if (this.isOpen) return; // Early return if the modal is open
    evt.stopImmediatePropagation();
    evt.preventDefault();
    this.isOpen = true;

    if (this.okButton) {
      vex.dialog.buttons.YES.text = this.okButton;
    }

    vex.dialog.confirm({
      message: this.message,
      callback: (value) => {
        if (value) this.operation(evt);
        this.isOpen = false;
        return;
      },
    });
  }

  operation(evt) {
    this.element.dispatchEvent(evt);
  }
}

class RemoteFormConfirm extends Confirm {
  constructor({ element, utils }) {
    super({ element, utils, attribute: 'ct-remote-form-confirm' });
  }

  attachListeners() {
    this.element.addEventListener('submit', this.popup.bind(this));
  }
}

class FormConfirm extends Confirm {
  constructor({ element, utils }) {
    super({ element, utils, attribute: 'ct-form-confirm' });
  }

  attachListeners() {
    this.element.addEventListener('submit', this.popup.bind(this));
  }

  operation() {
    this.element.submit();
  }
}

class LinkConfirm extends Confirm {
  constructor({ element, utils }) {
    super({ element, utils, attribute: 'ct-link-confirm' });
  }

  attachListeners() {
    this.element.addEventListener('click', this.popup.bind(this));
  }
}

class DeleteConfirm extends Confirm {
  constructor({ element, utils }) {
    const i18nOpts = { scope: 'ct.components.confirm' };
    super({ element, utils, attribute: 'ct-delete-confirm', okButton: t('delete_ok', i18nOpts) });
  }

  attachListeners() {
    this.element.addEventListener('click', this.popup.bind(this));
  }
}

export { LinkConfirm, FormConfirm, RemoteFormConfirm, DeleteConfirm };
