import Utilities from 'Utilities/utilities.js'

class Loader {
  constructor(elements) {
    this.elements = elements
    this.utils = new Utilities()
    this.attachListeners = this.attachListeners.bind(this)

    this.attachAJAXListener()
  }

  attachListeners(evt, node) {
    Object.keys(this.elements).forEach((selector) => {
      Array.prototype.slice.call(this.utils.queryCTSelectorAll(selector, node)).forEach((ele) => {
        this.utils.cleanAttributes(ele)
        const klass = this.elements[selector]
        try {
          new klass({ utils: this.utils, element: ele }) // eslint-disable-line no-new
        } catch (e) {
          console.error(`Loader[${selector}]:`, e)
        }
      })
    })
  }

  attachAJAXListener() {
    document.body.loader = (node) => {
      ReactRailsUJS.mountComponents(node)
      this.attachListeners({}, node)
    }
  }
}

export default Loader
