class FormSelectAll {
  constructor({ element }) {
    this.targetCheckbox = element

    this.checkboxes = document.querySelectorAll(`[ct-form-fields="${this.name}"] input[type="checkbox"]`)

    this.attachListeners()
  }

  toggleCheckboxes() {
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = this.targetCheckbox.checked
    })
  }

  attachListeners() {
    this.targetCheckbox.addEventListener('click', this.toggleCheckboxes.bind(this))
  }

  get name() {
    return this.targetCheckbox.getAttribute('ct-form-select-all')
  }
}

export default FormSelectAll
