class Truncate {
  constructor({ element }) {
    this.element = element
    this.attachListeners()
    this.updateStyles()
  }

  attachListeners() {
    window.addEventListener('resize', this.updateStyles.bind(this))
  }

  updateStyles() {
    window.requestAnimationFrame(() => {
      this.element.classList.remove('text-truncate')
      this.width = null

      window.requestAnimationFrame(() => {
        this.width = this.newWidth
        this.element.classList.add('text-truncate')
      })
    })
  }

  get newWidth() {
    return `${this.element.getBoundingClientRect().width}px`
  }

  set width(value) {
    this.element.style.width = value
  }
}

export default Truncate
