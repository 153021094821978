import React from 'react'
import ReactDOM from 'react-dom'

import ControlledDateTimeInput from '../../components/Forms/ControlledDateTimeInput'

export default class ReactDatetimeInput {
  constructor({ element }) {
    this.container = element
    const input = element.querySelector('input')

    this.createInterface(input)
    // Remove the original element to be replaced by the React component
    input.remove()
  }

  createInterface({ id, name, value }) {
    const wrapper = document.createElement('div')
    this.container.appendChild(wrapper)
    const timeZone = this.container.getAttribute('ct-react-datetime-input')

    ReactDOM.render(
      <ControlledDateTimeInput id={id} name={name} value={value} timeZone={timeZone} />,
      wrapper
    )
  }
}
