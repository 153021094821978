import FormSelectAll from './form_select_all'

class FormOnlySelected extends FormSelectAll {
  constructor({ element, utils }) {
    super({ element, utils })
  }

  toggleCheckboxes() {
    this.checkboxes.forEach((checkbox) => {
      if (this.targetCheckbox.checked) {
        if (!checkbox.checked) {
          checkbox.parentElement.style.display = 'none'
        }
      } else {
        checkbox.parentElement.style.display = 'block'
      }
    }, this)
  }

  get name() {
    return this.targetCheckbox.getAttribute('ct-form-only-selected')
  }
}

export default FormOnlySelected
