import { t } from 'i18n'
import keycodes from 'Utilities/keycodes'

class Validator {
  constructor({ element }) {
    this.form = element

    this.attachListeners = this.attachListeners.bind(this)
    this.onInvalid = this.onInvalid.bind(this)
    this.validate = this.validate.bind(this)
    this.submit = this.submit.bind(this)
    this.reset = this.reset.bind(this)
    this.attachListeners()
  }

  get inputs() {
    return this.form.querySelectorAll(`
      input[type=email]:not(:disabled):not([readonly]),
      input[type=tel]:not(:disabled):not([readonly]),
      input:not(:disabled):not([readonly]):not([type=hidden]),
      textarea[required]:not(:disabled):not([readonly])
    `)
  }

  get selects() {
    return this.form.querySelectorAll('select:not(:disabled):not([readonly])')
  }

  attachListeners() {
    this.form.addEventListener('autosubmit', this.submit)
    this.form.addEventListener('submit', this.submit)
    this.form.addEventListener('reset', this.reset)

    this.inputs.forEach((input) => {
      input.addEventListener('invalid', this.onInvalid)
      input.addEventListener('keyup', this.validate)
      input.addEventListener('change', this.validate)
      input.clearErrors = this.clearErrors
    })

    this.selects.forEach((select) => {
      select.addEventListener('invalid', this.onInvalid)
      select.addEventListener('change', this.validate)
      select.clearErrors = this.clearErrors
    })
  }

  error(input) {
    const errorEl = document.createElement('div')
    errorEl.classList.add('error')
    errorEl.innerText = input.validity.customError
      ? input.validationMessage
      : t(input.type, { scope: 'ct.components.validator' })
    return errorEl
  }

  validate(evt) {
    // If key is the TAB key the key up event target will be the next element
    let inputEl = evt.target
    if (keycodes[evt.which] === 'TAB') {
      const previous = this.inputs[Array.prototype.slice.call(this.inputs).indexOf(evt.target) - 1]
      if (!previous) return
      inputEl = previous
    }

    if (inputEl.validity.customError) {
      inputEl.setCustomValidity('')
    }

    if (inputEl.checkValidity() && inputEl.clearErrors) {
      inputEl.clearErrors()
    }
    return
  }

  clearErrors() {
    const wrapper = this.parentElement
    wrapper.classList.remove('input--errors')
    const errorMsg = wrapper.querySelector('div.error')
    if (errorMsg) errorMsg.remove()
    return
  }

  onInvalid(evt) {
    const inputEl = evt.target
    const wrapper = inputEl.parentElement

    if (!wrapper.classList.contains('input--errors')) {
      wrapper.classList.add('input--errors')

      const lastAudited = wrapper.querySelector('.audited')
      const error = this.error(inputEl)

      if (lastAudited) {
        wrapper.insertBefore(error, lastAudited)
      } else {
        wrapper.appendChild(error)
      }
    }
  }

  submit(evt) {
    if (!this.form.checkValidity() || this.form.getAttribute('disabled') == 'disabled') { // eslint-disable-line eqeqeq
      evt.preventDefault()
      evt.stopImmediatePropagation()

      return false
    }
  }

  reset() {
    this.form.removeAttribute('disabled')
    this.form
      .querySelectorAll(
        `
      input[required]:not([readonly]):not([type=hidden]), textarea[required]:not([readonly])
    `
      )
      .forEach((input) => input.clearErrors())
  }
}

export default Validator
