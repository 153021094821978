import vex from 'CT/vex'

class BrowserCheck {
  constructor({ element }) {
    this.content = element.innerHTML

    this.browserCheck()
  }

  browserCheck() {
    // see: https://stackoverflow.com/a/22242528
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
      vex.open({ unsafeContent: this.content })
    }
  }
}

export default BrowserCheck
