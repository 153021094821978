class Dropdown {
  constructor({ element }) {
    this.element = element
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.element.addEventListener('click', this.open)
  }

  get backdrop() {
    return (
      document.querySelector('.vex-overlay') || document.querySelector('.backdrop') || this.createBackdrop()
    )
  }

  get dropdownMenu() {
    return document.querySelector(this.element.getAttribute('ct-dropdown'))
  }

  createBackdrop() {
    const backdrop = document.createElement('div')
    backdrop.classList.add('backdrop')
    document.body.appendChild(backdrop)
    return backdrop
  }

  open(evt) {
    evt.preventDefault()
    this.dropdownMenu.classList.add(this.element.getAttribute('ct-dropdown-toggle'))
    this.backdrop.classList.add('backdrop--show')
    this.backdrop.addEventListener('click', this.close)
  }

  close(evt) {
    if (evt.target !== this.backdrop) return
    evt.preventDefault()
    this.backdrop.classList.remove('backdrop--show')
    this.backdrop.removeEventListener('click', this.close)
    this.dropdownMenu.classList.remove(this.element.getAttribute('ct-dropdown-toggle'))
  }
}

export default Dropdown
