import React from 'react'
import ReactDOM from 'react-dom'

import Datetime from 'react-datetime'

import moment from 'ct-moment'
import 'moment-timezone'

export default class ReactDateInput {
  constructor({ element }) {
    this.container = element
    const input = element.querySelector('input')

    if (input) {
      this.createInterface(input)
      input.remove()
    } else {
      const selects = this.container.querySelectorAll('select')
      const value = `${selects[0].value}-${selects[1].value}-${selects[2].value}`
      const name = selects[0].name.replace('(1i)', '')
      const id = selects[0].id
      const disabled = selects[0].disabled

      this.createInterface({ id, value, name, disabled })
      selects.forEach((select) => select.remove())
    }
  }

  createInterface({ id, name, value, disabled = false }) {
    const wrapper = document.createElement('div')

    const lastAudited = this.container.querySelector('.audited')
    const error = this.container.querySelector('.error')

    if (lastAudited) {
      this.container.insertBefore(wrapper, lastAudited)
    } else if (error) {
      this.container.insertBefore(wrapper, error)
    } else {
      this.container.appendChild(wrapper)
    }

    const timeZone = this.container.getAttribute('ct-react-date-input')
    const initialValue = moment(value, 'YYYY-MM-DD HH:mm:SS Z').tz(timeZone)

    ReactDOM.render(
      <Datetime
        closeOnSelect
        defaultValue={initialValue}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        inputProps={{ id, name, className: 'string', disabled }}
      />,
      wrapper
    )
  }
}
