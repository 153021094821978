class AddFields {
  constructor({ element }) {
    element.addEventListener('click', (evt) => {
      evt.preventDefault()

      const fields = element.getAttribute('ct-add-fields')
      const id = element.getAttribute('ct-add-fields-id')

      const time = new Date().getTime()
      const regexp = new RegExp(id, 'g')

      const div = document.createElement('div')
      div.innerHTML = fields.replace(regexp, time)

      element.parentNode.insertBefore(div, element)

      document.body.loader(div)
    })
  }
}

export default AddFields
