class Accordion {
  static get SECTIONS() {
    return {
      'care-plan-section': CarePlanSection,
    }
  }

  constructor({ element }) {
    this.container = element
    this.type = element.getAttribute('ct-accordion')
    this.storageKey = element.getAttribute('ct-accordion-key')

    this.toggleClasses = element.getAttribute('ct-accordion-class').split('|')
    this.sections = this.container.querySelectorAll('[ct-accordion-section]')
    this.openSectionId =
      window.location.hash || element.getAttribute('ct-accordion-open-section') || `#${this.sections[0].id}`

    this.sections.forEach(this.createClass.bind(this))
  }

  createClass(element, i) {
    const klass = Accordion.SECTIONS[this.type]
    const id = `#${element.getAttribute('ct-accordion-section')}`
    const isOpen = this.openSectionId === id
    const nextSection = this.sections[i + 1]
    const toggleClasses = this.toggleClasses
    const isComplete = this.isComplete(id)
    const addKey = this.complete.bind(this, id)

    new klass({ // eslint-disable-line no-new
      index: i,
      element,
      toggleClasses,
      nextSection,
      isOpen,
      isComplete,
      addKey,
    })
  }

  isComplete(id) {
    const completeItems = JSON.parse(localStorage.getItem(this.type))
    if (completeItems && completeItems[this.storageKey]) {
      return completeItems[this.storageKey].includes(id)
    }
    return false
  }

  complete(id) {
    let completeItems = JSON.parse(localStorage.getItem(this.type))
    if (completeItems) {
      const items = completeItems[this.storageKey] || []
      if (!items.includes(id)) {
        items.push(id)
      }
      completeItems[this.storageKey] = items
    } else {
      completeItems = {}
      completeItems[this.storageKey] = [id]
    }

    localStorage.setItem(this.type, JSON.stringify(completeItems))
  }
}

class CarePlanSection {
  constructor({ index, element, toggleClasses, nextSection, isOpen, isComplete, addKey }) {
    this.section = element

    this.id = element.getAttribute('ct-accordion-section')
    this.nextSectionId = nextSection && nextSection.getAttribute('ct-accordion-section')

    this.toggleClasses = toggleClasses

    this.initialTop = index === 0 ? 0 : this.section.getBoundingClientRect().top

    this.isOpen = isOpen
    this.isComplete = isComplete
    this.addKey = addKey
    this.menu = document.querySelector(`[ct-accordion-menu="${this.id}"]`)
    this.attachListeners()

    if (this.isOpen) this.open()
    if (this.isComplete) this.complete()
  }

  attachListeners() {
    window.addEventListener('hashchange', this.handlePopState.bind(this))

    const form = this.section.querySelector('form')
    if (!form) return
    form.addEventListener('ajax:success', this.next.bind(this))
  }

  handlePopState() {
    if (window.location.hash.match(new RegExp(`#${this.id}`))) {
      this.open()
      return
    }
    this.close()
  }

  next(evt) {
    if (
      this.nextSectionId &&
      !evt.detail.autosubmitted &&
      !this.section.hasAttribute('ct-accordion-stay-in-place')
    ) {
      window.location.hash = this.nextSectionId
    }
  }

  close() {
    window.requestAnimationFrame(() => {
      this.toggleClasses.forEach((klass) => this.section.classList.add(klass))
      this.menu.classList.remove('sidebar-section__item--active')
      this.section.classList.remove('careplan-wizard__section--open')
      if (this.isComplete) {
        this.section.classList.add('careplan-wizard__section--complete')
      }
    })

    this.isOpen = false
  }

  open() {
    window.scrollTo(0, this.initialTop)
    window.requestAnimationFrame(() => {
      this.toggleClasses.forEach((klass) => this.section.classList.remove(klass))
      this.section.classList.add('careplan-wizard__section--open')

      if (this.menu) {
        this.menu.classList.add('sidebar-section__item--active')
      }
    })

    this.isOpen = true
    window.addEventListener('hashchange', this.complete.bind(this), { once: true })
  }

  complete() {
    window.requestAnimationFrame(() => {
      this.section.classList.add('careplan-wizard__section--complete')
      if (this.menu) {
        this.menu.classList.add('sidebar-section__item--complete')
      }
    })

    this.addKey()
    this.isComplete = true
  }
}

export default Accordion
